<script setup>
import { ref, computed, reactive } from 'vue'
import { useSubStore } from '@/stores/Sub'

const gtm = useGtm()
const { errors, verifyEmail } = useValidators()
const sub = useSubStore()
const step = ref(1)
const form = reactive({ email: '' })
const initialForm = JSON.stringify(form)

// Disable subscribe button until all errors are cleared
const isSubscribeButtonDisabled = computed(() => {
    let disabled = true

    // Process if form is changed
    if (JSON.stringify(form) === initialForm) {
        disabled = true
    } else {
        for (let prop in form) {
            if (!form[prop] || errors[prop]) {
                disabled = true
                break
            }
            disabled = false
        }
    }

    return disabled
})

// Title of newsletter window
const stepTitle = computed(() => {
    switch (step.value) {
        case 1:
            return 'Subcribe to Our Newsletter'
        default:
            return 'Thanks for signing up!'
    }
})

// Submit form
async function submitForm() {
    if (!errors['email'] && form.email.length > 0) {
        await verifyEmail('email', form.email)
        // if no errors show thanks window
        if (!errors['email']) {
            sub.email = form.email
            await sub.subscribe()
            if (sub.error) {
                errors['email'] = 'Sorry, something went wrong, please try again later'
                gtm.trackEvent({
                    event: 'newsletter_signup',
                    status: 'failed',
                    errorMsg: sub.error,
                    subEmail: sub.email.replace('@', '[AT]'),
                })
            } else {
                step.value++
            }
        }
    }
}
</script>

<template>
    <v-row id="appnewsletter" class="justify-center align-center">
        <v-col>
            <v-card class="pa-4" variant="flat">
                <v-card-title class="text-h6 text-sm-h4 font-weight-bold text-center justify-center break-title">
                    <span class="d-flex mb-2 justify-center">{{ stepTitle }}</span>
                </v-card-title>
                <v-window v-model="step">
                    <v-window-item :value="1" class="mx-4 mx-md-16 mt-2">
                        <app-field-email
                            v-model="form.email"
                            variant="outlined"
                            density="compact"
                            prepend-inner-icon="mdi-email"
                        />
                    </v-window-item>

                    <v-window-item :value="2">
                        <div class="d-flex flex-column mt-4 fill-height justify-center align-center">
                            <v-icon size="70" color="success">mdi-email-newsletter</v-icon>
                            <span class="text-grey-darken-4"> We have sent a confirmation e-mail. </span>
                        </div>
                    </v-window-item>
                </v-window>
                <app-button
                    id="btnsubscribe"
                    v-if="step === 1"
                    :disabled="isSubscribeButtonDisabled"
                    :btn-text="`Subscribe`"
                    @click.stop="submitForm"
                    class="my-2 d-flex justify-center"
                    aria-label="Subscribe to newsletter"
                />
                <app-button
                    v-if="step === 2"
                    :to="`/shop`"
                    :btn-text="`Shop`"
                    class="my-2 d-flex justify-center"
                    aria-label="Visit satYatunes shop"
                />
                <v-divider></v-divider>
                <v-card-text>
                    <div class="text-center break-title font-weight-medium">
                        By clicking on "Subscribe", you agree to our
                        <nuxt-link to="/privacy" class="st-links" aria-label="Privacy policy">
                            Privacy Policy
                        </nuxt-link>
                        and
                        <nuxt-link to="/terms" class="st-links" aria-label="Terms of use"> Terms of Use </nuxt-link>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<style scoped>
/* Transparency of newsletter form window */
.bg-newsletter-light {
    background: rgba(245, 245, 245, 0.9) !important;
}
.bg-newsletter-dark {
    background: rgba(34, 34, 34, 0.8) !important;
}
</style>
