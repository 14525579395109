<script setup>
import { useAppStore } from '@/stores/App'
import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()
const app = useAppStore()
</script>

<template>
    <v-row v-if="mdAndUp" id="prlxnewsletter">
        <v-col md="4" lg="6" xl="7" class="pa-0">
            <v-parallax id="prlxImg" height="400" :src="app.parallaxImage" alt="Parallax image"> </v-parallax>
        </v-col>
        <v-col md="8" lg="6" xl="5" class="st-bg-gradient-yellow-anim d-flex flex-column align-center">
            <app-newsletter />
        </v-col>
    </v-row>
    <v-row v-else id="nonprlxnewsletter">
        <v-col cols="12" class="st-bg-gradient-yellow-anim d-flex flex-column justify-center align-center rounded-t">
            <app-newsletter />
        </v-col>
    </v-row>
</template>

<style scoped></style>
